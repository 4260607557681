const on = (event, selector, handler) => {
  document.addEventListener(event, e => {
    const closest = e.target.closest(selector);

    if (closest) {
      handler(closest, e);
    }
  });
};

const ready = handler => {
  document.addEventListener("DOMContentLoaded", handler);
};

// eslint-disable-next-line import/prefer-default-export
export { on, ready };
